<script setup lang="ts">
import {
  computed,
  toValue,
  onMounted,
  onUnmounted,
  ref,
  useTemplateRef,
  watchEffect,
} from 'vue';
import * as UC from '@uploadcare/file-uploader';
import '@uploadcare/file-uploader/web/uc-file-uploader-regular.min.css';
import strings from '../../../../settings-strings';
import { toast, ToastStatus } from '@mfl/common-components';
import { settingsGateway } from '@msl/settings-gateway-sdk';
import {
  WsSelect,
  WsSelectOption,
  WsToggle,
  WsButton,
} from '@mfl/common-components';

import {
  ensureWrappedWithBraces,
  isInBraces,
  stripBraces,
} from '../../../../settings-utils';

UC.defineComponents(UC);

const IMAGE_BASE_URL = 'https://d36urhup7zbd7q.cloudfront.net';
const uploadcarePubkey = import.meta.env.VITE_UPLOADCARE_PUBKEY;

const props = defineProps<{
  options: Array<{
    options: Array<{
      value: string;
      label: string;
    }>;
  }>;
  disabled: boolean;
  toggleAid: string;
  inputAid: string;
  selectAid: string;
}>();

const previewImageUrl = ref<string>();

const model = defineModel<string>({
  required: true,
  default: '',
});

const localValue = computed<{
  value: string;
  useVariable: boolean;
}>({
  get() {
    const useVariable = isInBraces(model.value);
    return {
      value: stripBraces(model.value),
      useVariable,
    };
  },
  set(v) {
    model.value = v.useVariable
      ? ensureWrappedWithBraces(v.value)
      : stripBraces(v.value);
  },
});

let beforeToggleValue = '';

const localOptions = computed(() =>
  props.options.map(({ options }) => {
    return {
      options: options.map((o) => o.value),
    };
  })
);

const localLabels = computed(() =>
  props.options.reduce(
    (acc, { options }) => {
      return options.reduce((acc, o) => {
        acc[o.value] = o.label;
        return acc;
      }, acc);
    },
    { '{{}}': strings.select, '': strings.select } as Record<string, string>
  )
);

const handleUseVariableToggle = (v: boolean) => {
  const oldValue = toValue(localValue).value;
  if (v) {
    localValue.value = {
      useVariable: true,
      value: beforeToggleValue,
    };
  } else {
    localValue.value = {
      value: beforeToggleValue,
      useVariable: v,
    };
  }
  beforeToggleValue = oldValue;
};

const handleSelectChange = (v: unknown) => {
  localValue.value = {
    ...localValue.value,
    value: v as string,
  };
};

const uploadcareConfig = useTemplateRef<UC.ConfigType>('uploadcare-config');
const uploaderCtx =
  useTemplateRef<InstanceType<UC.UploadCtxProvider>>('uploader-ctx');

async function onDoneClick(event: CustomEvent<UC.EventPayload['done-click']>) {
  const entry = event.detail.successEntries[0];

  try {
    const res = await settingsGateway.uploadBrandingLogo({
      uuid: entry.uuid,
      cdnUrl: entry.cdnUrl,
      imageFormat: entry.fileInfo.imageInfo?.format,
    });

    if (!res.objectKey) {
      throw new Error('Got no objectKey from upload');
    }

    model.value = new URL(res.objectKey, IMAGE_BASE_URL).href;
  } catch (error) {
    toast({
      aid: 'UNEXPECTED_ERROR',
      message: strings.employeePortalLogoUploadUnexpectedError,
      status: ToastStatus.Error,
    });

    throw error;
  }
}

const hasLogo = computed(() =>
  Boolean(model.value && !isInBraces(model.value))
);

async function getImgFromMacro(
  macroOrUrl: string
): Promise<string | undefined> {
  const macroContent = stripBraces(macroOrUrl);
  const [category, property] = macroContent.split('.');

  if (!category || !property) {
    return;
  }

  const response = await settingsGateway.getDomainGeneralInfo({});

  if (!response.result?.domainData?.macros?.company) {
    return;
  }

  const macro = response.result.domainData.macros.company.find(
    (m) => m.property === property
  );

  return macro?.value;
}

onMounted(() => {
  if (!uploadcareConfig.value || !uploaderCtx.value) return;

  uploadcareConfig.value.iconHrefResolver = ((iconName) => {
    if (iconName === 'default') {
      return '#uc-custom-icon-default';
    }
  }) as UC.ConfigType['iconHrefResolver'];

  uploadcareConfig.value.localeDefinitionOverride = {
    en: {
      'drop-files-here': 'Drop logo here',
    },
  };

  uploaderCtx.value.addEventListener('done-click', onDoneClick);
});

onUnmounted(() => {
  if (!uploaderCtx.value) return;

  uploaderCtx.value.removeEventListener('done-click', onDoneClick);
});

watchEffect(async () => {
  if (model.value && isInBraces(model.value)) {
    previewImageUrl.value = await getImgFromMacro(model.value);
  }
});
</script>

<template>
  <div class="logo-field-select-container">
    <div class="logo-field-select-content">
      <template v-if="localValue.useVariable">
        <div class="logo-field-select-header">
          <div class="logo-field-select-spacer"></div>
          <div class="logo-field-select-toggle">
            <p class="logo-toggle-label">
              {{ strings.employeePortalBrandingFieldToggleLabel }}
            </p>
            <ws-toggle
              v-model:model-value="localValue.useVariable"
              :aid="props.toggleAid"
              :disabled="props.disabled"
              @update:model-value="handleUseVariableToggle"
            />
          </div>
        </div>
        <div class="logo-field-select-control">
          <ws-select
            :model-value="localValue.value"
            :option-label="(v) => localLabels[v]"
            :option-key="(v) => v"
            :aid="props.selectAid"
            :disabled="props.disabled"
            @update:model-value="handleSelectChange"
          >
            <template v-for="group in localOptions" :key="group">
              <template v-if="group.options?.length > 0">
                <WsSelectOption
                  v-for="opt in group.options"
                  :key="opt"
                  :value="opt"
                >
                  {{ localLabels[opt] }}
                </WsSelectOption>
              </template>
            </template>
          </ws-select>

          <img
            v-if="previewImageUrl"
            :src="previewImageUrl"
            alt="Company Logo"
            class="logo-preview"
          />
        </div>
      </template>

      <template v-else>
        <div class="logo-field-select-inline">
          <div class="logo-field-upload">
            <div v-if="hasLogo" class="logo-field-preview">
              <img :src="model" alt="Company Logo" class="logo-preview" />
              <ws-button
                variant="text"
                :disabled="props.disabled"
                :label="strings.employeePortalBrandingEditImagesBtn"
                :aid="`${props.inputAid}_EDIT`"
                size="md"
                class="edit-button"
                @click="uploaderCtx?.getAPI().initFlow()"
              />
            </div>

            <ws-button
              v-else
              :disabled="props.disabled"
              :label="strings.employeePortalBrandingUploadImagesBtn"
              :aid="props.inputAid"
              size="md"
              class="upload-button"
              @click="uploaderCtx?.getAPI().initFlow()"
            />
          </div>
          <div class="logo-field-select-toggle">
            <p class="logo-toggle-label">
              {{ strings.employeePortalBrandingFieldToggleLabel }}
            </p>
            <ws-toggle
              v-model:model-value="localValue.useVariable"
              :aid="props.toggleAid"
              :disabled="props.disabled"
              @update:model-value="handleUseVariableToggle"
            />
          </div>
        </div>
      </template>
    </div>

    <uc-config
      ref="uploadcare-config"
      ctx-name="logo-uploader"
      :pubkey="uploadcarePubkey"
      multiple="false"
      img-only="true"
      source-list="local, url, dropbox, gdrive"
      max-local-file-size-bytes="10485760"
    />

    <uc-upload-ctx-provider ref="uploader-ctx" ctx-name="logo-uploader" />

    <uc-file-uploader-regular
      ctx-name="logo-uploader"
      headless="true"
      class="uc-light"
    />

    <svg xmlns="http://www.w3.org/2000/svg" class="hidden">
      <symbol id="uc-custom-icon-default" viewBox="0 0 26 26" fill="none">
        <rect width="26" height="26" rx="13" class="fill-primary" />
        <path
          fill="white"
          d="M13.75 7.25C13.75 6.83437 13.4156 6.5 13 6.5C12.5844 6.5 12.25 6.83437 12.25 7.25V12.25H7.25C6.83437 12.25 6.5 12.5844 6.5 13C6.5 13.4156 6.83437 13.75 7.25 13.75H12.25V18.75C12.25 19.1656 12.5844 19.5 13 19.5C13.4156 19.5 13.75 19.1656 13.75 18.75V13.75H18.75C19.1656 13.75 19.5 13.4156 19.5 13C19.5 12.5844 19.1656 12.25 18.75 12.25H13.75V7.25Z"
        />
      </symbol>
    </svg>
  </div>
</template>

<style scoped lang="scss">
.logo-field-select-container {
  width: 100%;

  .logo-field-select-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .logo-field-select-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .logo-field-select-spacer {
      flex: 1;
    }
  }

  .logo-field-select-inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .logo-field-select-toggle {
    display: flex;
    align-items: center;
    gap: 8px;

    .logo-toggle-label {
      font-size: 14px;
      color: var(--text-secondary);
      margin: 0;
    }
  }

  .logo-field-select-control {
    width: 100%;

    :deep(.ws-select) {
      width: 100%;
      max-width: 320px;
    }
  }

  .upload-button {
    width: fit-content;
  }

  .logo-field-upload {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .logo-preview {
    height: 40px;
    max-width: 130px;
    object-fit: contain;
    margin-top: 14px;
  }

  .logo-field-preview {
    flex-direction: column;
    gap: 4px;

    .edit-button {
      color: var(--color-primary);
      font-size: 0.75rem;
      justify-content: start;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

uc-file-uploader-regular {
  --uc-font-family: Mulish, sans-serif;
  --uc-button-size: 38px;
  --uc-radius: calc(8px / 1.75);
  --uc-dialog-shadow-light: var(--box-shadow);
  --uc-foreground-light: var(--color-gray-500);
  --uc-primary-rgb-light: var(--color-primary);
  --uc-secondary-foreground-light: var(--color-gray-500);

  :where(.uc-cancel-btn, .uc-done-btn, .uc-url-upload-btn) {
    padding-right: 14px;
    padding-left: 14px;
  }

  :deep(uc-drop-area) {
    border-radius: 6px;
    border: 2px dashed rgb(var(--color-gray-200));
    background-color: rgb(var(--color-primary-50));

    uc-icon[name='default'] > svg {
      width: 32px;
      height: 32px;
    }

    .uc-content-wrapper .uc-text {
      font-weight: 700;
    }
  }

  :deep(uc-start-from .uc-secondary-btn) {
    border-radius: 30px;
    padding: 10px 22px;
    border: 1px solid rgb(var(--color-gray-200));
    background-color: white;
    font-weight: 600;
  }
}
</style>
