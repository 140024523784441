<script setup lang="ts">
import { ref } from 'vue';
import { WsColorPicker } from '@mfl/common-components';

defineProps<{
  disabled?: boolean;
  aid?: string;
}>();

const color = defineModel<string>();

const showColorPicker = ref(false);

const handleColorUpdate = (newColor: string) => {
  color.value = newColor;
  showColorPicker.value = false;
};
</script>

<template>
  <div class="color-picker-container">
    <div class="color-input-row">
      <div
        :style="{ backgroundColor: color || '#FFFFFF' }"
        @click="!disabled && (showColorPicker = !showColorPicker)"
        :class="['color-preview', disabled ? 'disabled' : '']"
      ></div>
    </div>
    <div v-if="showColorPicker" class="color-picker-popup">
      <WsColorPicker
        :model-value="color"
        :aid="aid"
        @update:model-value="handleColorUpdate"
      />
    </div>
  </div>
</template>

<style scoped>
.color-picker-container {
  position: relative;
}

.color-input-row {
  display: flex;
  align-items: center;
  gap: 6px;
}
.color-preview {
  display: flex;
  width: 31px;
  height: 31px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--Gray-200, #e0e0e0);
}

.color-input {
  width: 100px;
}

.color-preview.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.color-picker-popup {
  position: absolute;
  top: 100%;
  margin-top: 8px;
  z-index: 100;
}
</style>
